import React,
  { useState, useEffect }
from 'react';
import Link from 'next/link'
import Head from 'next/head'
import UserInfo from './common/UserInfo'
import UserHeaderButtons from './common/UserHeaderButtons'
import Footer from './common/Footer'
import StartButton from './common/StartButton'
import DelayRender from './post/DelayRender';


export const Home = props => {
  const [cards, setCards] = useState();
  const data = props.data

  useEffect(() => {
    if (!data || !data.posts) return;

    const c = [];
    for (const post of data.posts) {
      c.push(
        <>
          <hr className="my-10" />
          <Link href={post.url} className="flex flex-row px-3">
            <div className="grow md:pr-4">
              <div className="flex flex-row ">
                <div className="text-xl grow break-all pr-2 md:pr-0">
                  {post.title}
                </div>
                <div className="block md:hidden basis-1/4 flex-none">
                  {ResponsiveImages(post, false)}
                </div>
              </div>
              <div className="text-sm text-gray-500 mt-4 break-all leading-relaxed">
                {post.subtitle?.substring(0, 120)}{ post.subtitle?.length > 120 ? "..." : ""}
              </div>

              <div className="mt-4 w-full flex">
                <UserInfo
                  user={post.user}
                  publishedAt={post.publishedAt}
                />
                <div className="flex-grow" />
              </div>
            </div>
            <div className="hidden md:block flex-none basis-1/4">
              {ResponsiveImages(post, true)}
            </div>
          </Link>
        </>
      );
    }
    setCards(c);
  }, [data]);

  const ResponsiveImages = (post, md) => {
    if (!post.thumbnail160) {
      return (
        <img
          src={post.thumbnail}
          alt="post"
          className={
            md
              ? "object-cover w-44 bg-white h-40 rounded-lg"
              : "object-cover bg-white h-20 w-full rounded-lg"
          }
        />
      );
    }

    return (
      <img
        id="srcSet"
        alt="post"
        className={
          md
            ? "object-cover w-44 bg-white h-40 rounded-lg"
            : "object-cover bg-white h-20 w-full rounded-lg"
        }
        srcSet={`${post.thumbnail160.url},
                ${post.thumbnail320.url} 2x,
                ${post.thumbnail480.url} 3x,
                ${post.thumbnail640.url} 4x`}
        src={post.thumbnail160.url}
      />
    );
  }

  return (
    <>
      <Head>
        <title>みんなのニュースレター｜無料でニュースレター配信をはじめよう</title>
        <meta property="twitter:title" content="みんなのニュースレター" />
        <meta
          name="description"
          content="みんなのニュースレターではニュースレターの配信ができます。コンテンツの販売、コミュニティ運営が無料で開始できます。"
        />
        <meta
          property="twitter:description"
          content="みんなのニュースレターではニュースレターの配信ができます。コンテンツの販売、コミュニティ運営が無料で開始できます。"
        />
        <meta
          property="og:description"
          content="みんなのニュースレターではニュースレターの配信ができます。コンテンツの販売、コミュニティ運営が無料で開始できます。"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          property="og:image"
          content="https://static-files.pages.dev/m-newsletter/images/ogp.png"
        />
        <meta
          property="twitter:image"
          content="https://static-files.pages.dev/m-newsletter/images/ogp.png"
        />
      </Head>
      <UserHeaderButtons />

      <main className="max-w-2xl mx-auto px-2 md:px-0 ">
        <div className="pt-16 md:pt-32 pb-20">
          <img
            src="https://static-files.pages.dev/m-newsletter/images/mail.svg"
            className="mx-auto w-16"
            alt="mail"
            width={64}
            height={64}
          />
          <img
            src="https://static-files.pages.dev/m-newsletter/images/title.webp"
            className="mx-auto w-80"
            alt="みんなのニュースレター"
            width={320}
            height={31}
          />
        </div>
        <h1 className="leading-loose mb-8 text-base font-normal">
          誰でもニュースレターが書ける場所、読める場所
        </h1>

        <div className="mt-20">
          <h2 className="text-2xl font-normal">今日のおすすめニュースレター</h2>
          {cards}
          <hr className="my-10" />
          {cards && (
            <div className="text-center">
              ほかにもたくさんのニュースレターがあります 🎉
            </div>
          )}
        </div>

        {cards && (
          <div className="mx-auto w-fit mt-20">
            <StartButton free={false} />
          </div>
        )}

        <DelayRender>
          <div className="w-full  py-20 px-2 md:px-0">
            <div className="max-w-2xl mx-auto">
              <h2 className=" text-2xl mt-12 font-normal">
                「みんなのニュースレター」でできること
              </h2>
              <div className="mt-12 mb-4 flex items-center ">
                <img
                  src="/images/icons/pen.svg"
                  className="w-20"
                  alt="pen"
                  width={80}
                  height={80}
                />
                <div className="text-xl font-bold ml-4">
                  ニュースレターを作る
                </div>
              </div>
              ニュースレター記事を投稿して、メールで配信、ウェブサイトに公開できます
              <div className="mt-12 mb-4 flex items-center ">
                <img
                  src="/images/icons/plant.svg"
                  className="w-20"
                  alt="pen"
                  width={80}
                  height={80}
                />
                <div className="text-xl font-bold ml-4">ファンを育てる</div>
              </div>
              メール購読でファンとのつながりを作ります
              <div className="mt-12 mb-4 flex items-center ">
                <img
                  src="/images/icons/money.svg"
                  className="w-20"
                  alt="pen"
                  width={80}
                  height={80}
                />
                <div className="text-xl font-bold ml-4">収益を生み出す</div>
              </div>
              創作活動を支える収益を生み出します
            </div>
            <h2 className=" text-2xl font-normal mt-32 py-2 px-6 rounded-xl w-fit mx-auto">
              作ったニュースレターもその成果も
              <br />
              すべてはクリエイターのもの
            </h2>
            <div className="p-6 rounded-xl bg-gray-100 mt-12 md:mt-20">
              <div className="mb-4 flex items-center ">
                <img
                  src="/images/icons/one.svg"
                  className="w-20"
                  alt="one"
                  width={80}
                  height={80}
                />
                <div className="ml-4 text-2xl font-bold">
                  購読者アドレスはクリエイターのもの
                </div>
              </div>
              獲得した購読者のメールアドレスは全てクリエイターのもの。メールリストは大切な顧客リスト。エクスポートして手元に置けます。
            </div>
            <div className="p-6 rounded-xl bg-gray-100 mt-20">
              <div className="mb-4 flex items-center ">
                <img
                  src="/images/icons/two.svg"
                  className="w-20"
                  alt="two"
                  width={80}
                  height={80}
                />
                <div className="ml-4 text-2xl font-bold">
                  ニュースレターはクリエイターのもの
                </div>
              </div>
              投稿したニュースレター記事は全てエクスポートできます。いつでも移行できます。
            </div>
            <div className="p-6 rounded-xl bg-gray-100 mt-20">
              <div className="mb-4 flex items-center ">
                <img
                  src="/images/icons/three.svg"
                  className="w-20"
                  alt="three"
                  width={80}
                  height={80}
                />
                <div className="ml-4 text-2xl font-bold">
                  手数料は収益が入った時だけ
                </div>
              </div>
              手数料（13.6％）が発生するのはクリエイターに収益が入った時だけ。収益が無ければ永年ゼロ円。
            </div>
          </div>
        </DelayRender>

        <DelayRender>
          <div className="mx-auto w-fit mt-20">
            <StartButton free={true} />
          </div>
        </DelayRender>
      </main>

      <DelayRender>
        <Footer />
      </DelayRender>
    </>
  );
}

export default Home;
