import React, { useEffect, useRef, useState, useContext } from "react";
import { userResponsiveImage } from "./Utils";

export const UserInfo = ({
  user, publishedAt
}) => {

  if(!user) {
    return null
  }

  return (
    <>
     {userResponsiveImage(user, "rounded-full w-10 h-10 my-auto")}
      <div className="my-auto ml-2">
        <div className="font-bold">{user.displayName}</div>
        <div className="text-gray-500 text-sm">
          {(publishedAt || new Date().toJSON()).slice(0, 10).replace(/-/g, "/")}
        </div>
      </div>
    </>
  );
}

export default UserInfo;
