import Home from '../components/Home'
import axios from 'axios';
import { useRouter } from 'next/router'

export const HomePage = props => {
  const router = useRouter()

  return(
      <Home
        key={router.asPath}
        data={props.data}
      />
  )
}
export default HomePage

export async function getStaticProps() {

  try {
    const res = await axios.get(
      process.env.NEXT_PUBLIC_FASTLY + '/api/v1/posts'
    )
    return {
      props: {
        data: { posts: res.data }
      },
      revalidate: 6,
    }
  } catch (error) {
    return {
      props: {
        notFound: true
      }
    }
  }

}
